/* @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500;600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');

:root {
  --themeclr: #D2E828;
  --textclr: #DEC508;

  /* --navhover: #C766FC; */
  /* --greens: #74E842; */
  --backcolor: #0c0c0c;
  --whites: #fff;

  --navhover:#FF67FF;
  --greens:#D2E828;
}

a {
  text-decoration: none !important;
}

.no-bord {
  border: 0 !important;
}

body {
  background-color: #fff;
  /* background-color: var(--backcolor); */
  /* background-image: url(./Assets/images/bg.png); */
  color: #fff;
  /* font-family: medium; */
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Space Mono', monospace; */
  font-family: "Montserrat", sans-serif !important;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: black;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  /* background-color: #74E842; */
  /* background-image: linear-gradient(#c16bf0, #c16bf0); */
  background-color: var(--darkgreen);
}

/* @font-face {
  font-family: themefont;
  src: url(./Assets/Font/Gilroy-Black.ttf);
}
@font-face {
  font-family: thin;
  src: url(./Assets/Font/Gilroy-Thin.ttf);
}
@font-face {
  font-family: bold;
  src: url(./Assets/Font/Gilroy-Bold.ttf);
}
@font-face {
  font-family: extrabold;
  src: url(./Assets/Font/Gilroy-ExtraBold.ttf);
}
@font-face {
  font-family: medium;
  src: url(./Assets/Font/Gilroy-Medium.ttf);
}
@font-face {
  font-family: normal;
  src: url(./Assets/Font/Gilroy-Regular.ttf);
}
@font-face {
  font-family: light;
  src: url(./Assets/Font/Gilroy-Light.ttf);
} */
.container.container-theme {
  max-width: 90%;
}

.normal {
  /* font-family: normal; */
}

.bold {
  /* font-family: bold; */
}

.innerheading {
  font-size: 40px;
  line-height: 56px;
  text-align: center;
}

.themeclr {
  color: var(--greens) !important;
}

.textclr {
  color: var(--black)
}

.flex-1>* {
  flex: 1;
}

.jc-between {
  justify-content: space-between;
}

.br-14 {
  border-radius: 14px;
}

.bgtheme {
  /* background-color: var(--themeclr); */
}

button.ovalbtn {
  /* background: var(--themeclr);
  border-radius: 30px;
  padding: 4px 10px;
  font-weight: 700;
  border: 1px solid var(--themeclr); */
  /* background: #17a2c1; */
  /* border-radius: 30px; */
  padding: 8px 18px;
  font-weight: 500;
  border: 0;
  color: #fff;
}

.btn.getstarted {
  background: var(--themeclr);
  font-family: 'extrabold';
  border-radius: 0;
  position: relative;
  min-width: 120px;
  padding: 10px 20px;
}

.launchpad .launchbox .btn.getstarted,
.lockedstake .launchbox .btn.getstarted {
  background-color: #4008DE;
  color: #fff;
}

.launchpad .innerheading,
.lockedstake .innerheading {
  color: var(--whites);
}

.smallp {
  max-width: 50%;
  margin: auto;
  color: #cbcbcb;
}
.btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}
button.chocobtn {
  /* background: var(--themeclr);
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 20%;
  
  font-weight: 700;
  border: 1px solid var(--themeclr); */
  background: var(--darkgreen);
  font-size: 16px;
  padding: 7px 24px;
  border-radius: 7px;
  font-weight: 500;
  border: 0;
  color: #fff !important;
  min-width: fit-content;
}

button.btn.getstarted.dark {
  background: #000;
  color: #fff;
}

button.btn.getstarted:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid white;
  position: absolute;
  left: -5px;
  bottom: 4px;
}

section {
  /* margin-top: 65px; */
  /* margin-top: 100px; */
  /* padding-top: 100px; */
  /* margin-bottom: 75px; */
  /* padding-bottom: 75px; */
}

button.btn.getstarted:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid white;
  position: absolute;
  left: -5px;
  bottom: 4px;
  transition: 0.2s;
}

.ban-cont p,
.features-list p {
  font-family: normal;
  color: #CBCBCB;
  line-height: 2;
}

.btn.btn-theme {
  background-color: var(--textclr);
}

/* header */
.land .navbar-nav .getstarted {
  display: none;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #80808061;
  z-index: 777;
}

.header-content {
  /* justify-content: center; */
  justify-content: space-evenly;
}

/* .userbtn {
  background: url("./Assets/images/slide/walletbtn.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: #fff;
  min-width: 160px;
  min-height: 50px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 16px;
  animation: glow 1s infinite alternate;
}
.userbtn.small{
  min-width: 90px;
  min-height: 50px;
  font-size: 24px;
}
.userbtn:hover {
  color: #c766fc !important;
} */

.headingbtn {
  /* color: #64C839; */
  /* color: var(--greens); */
  background-color: var(--darkgreen);
  color: var(--black) !important;
 transition: 0.2s;
 padding: 10px 24px;
 font-weight: 500;
}

.headingbtn:hover{
  background-color: var(--violetbg);
  color: #fff !important;
  /* color: var(--greens) !important;
  filter: drop-shadow(2px 4px 6px #00d1ff33);
  transition: 0.5s; */
}
.header .bg-dark {
  background: var(--backcolor) !important;
}

.header .navbar-nav a:hover,
.header .navbar-nav a.nav-link.active {
  border-bottom: 2px solid var(--greens);
}

.navbar-dark .navbar-nav .nav-link {
  font-weight: 300;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--navhover) !important;
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show>.nav-link {
  color: var(--navhover) !important;
}

.header .navbar-nav a {
  border-bottom: 3px solid transparent;
  margin-left: 20px;
  margin-right: 20px;
}

.header .navbar-nav a:last-child {
  margin-right: 0;
}

.header .navbar-nav a:first-child {
  margin-left: 0;
}

.lockedstake nav.navbar .btn.getstarted,
.launchpad nav.navbar .btn.getstarted {
  max-width: 150px;
}

/* tabs */
ul.nav-tabs {
  width: 100%;
  max-width: 580px;
  padding-bottom: 15px;
  background-color: var(--darkbg);
  border-radius: 10px;
  padding: 18px;
  gap: 20px;
  margin: auto;
}

ul.nav-tabs li {
  flex: 1;
  white-space: nowrap;
}

li.nav-item button {
  background: #363636 !important;
  border-color: transparent !important;
  color: #fff;
  width: 100%;
  outline: 0;
  box-shadow: none;
  position: relative;
  border-radius: 8px !important;
}


li.nav-item button:hover {
  /* color: #fff !important; */
  color: var(--darkgreen) !important;
  /* border-bottom: 2px solid var(--darkgreen) !important; */
}

li.nav-item button.active {
  /* color: #000 !important; */
  background-color: var(--darkgreen) !important;
  /* border-radius: 5px; */
  color: var(--black) !important;
  /* border-bottom: 2px solid var(--darkgreen) !important; */
  /* background-image: linear-gradient(#74E842,#c16bf0) !important; */
  /* background: url("./Assets/images/btn-bg.png") !important;
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
  background-position: center;
  min-width: 130px;
  min-height: 46px; */
}

ul.nav-tabs {
  border: 0 !important;
}


.news {
  position: relative;
}

/* .newshead{margin-top:-24px;} */
.news .items {
  position: relative;
  height: 90vh;
}

.news .itemsfles {
  display: flex;
  align-items: start;
  flex-direction: column;
  max-height: 100vh;
  justify-content: center;
  width: 50%;
  height: 100%;
  min-height: 80vh;
  position: absolute;
  bottom: 0;
  left: 41px;
  right: 0;
  top: 0;
  background: #16151580;
  padding: 24px;
}

.news .itemsfles h2 {
  font-size: 50px;
  font-weight: 600;
  color: #fff;
}

.news .itemsfles p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.news .owl-carousel .owl-nav {
  position: absolute;
  bottom: 50px;
  right: 80px;
}

.news .owl-carousel .owl-nav button.owl-next {
  background-color: #ffffff80;
  color: #5e5e5e;
  font-size: 28px;

  border-radius: 50px;
  width: 42px;
  outline: none;
}

.news .owl-carousel .owl-nav button.owl-prev {
  /* background-color: #ffffff80;
      color: #5e5e5e; */
  background-color: var(--greens);
  color: var(--black);
  font-size: 28px;
  border-radius: 50px;
  width: 42px;
  outline: none;
}

.news .owl-carousel .owl-nav button.owl-prev:hover,
.news .owl-carousel .owl-nav button.owl-next:hover {
  /* background-color: #fff; */
  background-color: var(--greens);
    color: var(--black);
}

.Editor_pick {
  cursor: pointer;
}

.Editor_pick h2 {
  color: var(--black);
  /* font-family: extrabold; */
  padding: 20px 0;
  font-weight: 600;
}

.col_token:nth-child(even) .token_list {
  /* background-color: #DEC508;
  border: 1px solid #DEC508; */
  background-color: transparent;
  /* border: 1px solid #fff; */
  border-radius: 8px;
  margin-top: 0px;
  transition: all 0.5s ease;
  margin-bottom: 0px;
}

.token_list {
  /* background-color: #fff; */
  position: relative;
  overflow: hidden;
  background-color:transparent;
  border: 1px solid var(--violetbg);
  border-radius: 8px;
  margin-top: 0px;
  transition: all 0.5s ease;
  margin-bottom: 0px;
  transition: all .3s ease;
  /* background: url("./Assets/images/tablebg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; */
}
.token_list:hover{
  transform: translateY(-10px);
}
.token_list span {
  position: absolute;
  border-radius: 100vmax;
}

.top {
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  /* background: linear-gradient(90deg,
          transparent 50%,
          #74E842,
          #74E842); */
          background: linear-gradient(90deg, transparent 50%, var(--darkgreen), var(--darkgreen));
}

.bottom {
  right: 0;
  bottom: 0;
  height: 3px;
  background: linear-gradient(90deg,
  var(--darkgreen),
  var(--darkgreen),
          transparent 50%);
}

.right {
  top: 0;
  right: 0;
  width: 3px;
  height: 0;
  background: linear-gradient(180deg,
          transparent 30%,
          #74E842,
          #74E842,
      );
}

.left {
  left: 0;
  bottom: 0;
  width: 3px;
  height: 0;
  background: linear-gradient(180deg,
          var(--darkgreen),
          var(--darkgreen),
          transparent 70%);
}

.top {
  animation: animateTop 8s ease-in-out infinite;
}

.bottom {
  animation: animateBottom 8s ease-in-out infinite;
}

.right {
  animation: animateRight 8s ease-in-out infinite;
}

.left {
  animation: animateLeft 8s ease-in-out infinite;
}

@keyframes animateTop {
  25% {
      width: 100%;
      opacity: 1;
  }

  30%,
  100% {
      opacity: 0;
  }
}

@keyframes animateBottom {

  0%,
  50% {
      opacity: 0;
      width: 0;
  }

  75% {
      opacity: 1;
      width: 100%;
  }

  76%,
  100% {
      opacity: 0;
  }
}

@keyframes animateRight {

  0%,
  25% {
      opacity: 0;
      height: 0;
  }

  50% {
      opacity: 1;
      height: 100%;
  }

  55%,
  100% {
      height: 100%;
      opacity: 0;
  }
}

@keyframes animateLeft {

  0%,
  75% {
      opacity: 0;
      bottom: 0;
      height: 0;
  }

  100% {
      opacity: 1;
      height: 100%;
  }
}


.tokenimg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 250px;
  max-height: 250px;
  overflow: hidden;
}

.tokenimg img {
  /* min-height: 250px;
  object-fit: cover;
  max-width: 100%; */
  min-height: 260px;
  object-fit: contain;
  max-width: 94%;
  /* margin-top: 20px; */
  margin: auto;
  transition: transform .5s ease,-webkit-transform .5s ease;
}
.tokenimg img:hover{
  transform: scale(1.1);
}
.token_items{
  /* padding: 4px 14px; */
  padding: 4px 14px 20px 14px;
}
.token_items h2 {
  /* color: #000; */
  color: var(--black);
  /* font-family: extrabold; */
  /* padding: 8px; */
  margin-bottom: 0;
  font-size: 24px;
}

.token_items p {
  /* color: #000; */
  color: var(--black);
  /* padding: 8px; */
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
}

.launchpad .token_list {
  background-color: #DEC508;
  border: 1px solid #DEC508;
  border-radius: 5px;
  margin-top: 20px;
  transition: all 0.5s ease;
  margin-bottom: 6px;
  margin-right: 8px;
}

.launchpad .col_token .token_list {
  background-color: transparent;
  border: 1px solid var(--violetbg);
  border-radius: 5px;
  margin-top: 0px;
  transition: all 0.5s ease;
  margin-bottom: 0px;
  margin-right: 8px;
}

.launchpad .tokenimg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 250px;
  max-height: 250px;
  overflow: hidden;
  padding: 0;
}

.tokenimg img {
  min-height: 250px;
  object-fit: cover;
  max-width: 100%;
}

.newdetail {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  cursor: pointer;
}

.newdetail img {
  min-height: 100vh;
  max-width: 100%;
  object-fit: cover;
  min-width: 100%;
  /* min-height: 100vh;
    max-width: 30%;
    object-fit: contain;
    min-width: 30%;y
    margin: auto; */
}
.details img{
  min-height: 100vh !important;
    max-width: 100% !important;
    object-fit: cover !important;
    min-width: 100% !important;
    margin: auto !important;
}
.newdetailsec {
  /* background-color: #fff;
  margin-top: -100px; */
  background-color: transparent;
  margin-top: -100px;
  border: 1px solid var(--violetbg);
  border-radius: 8px;

}
.cowa{
  color: var(--black) !important;
  margin-bottom: 0;
}
.modal-header {
  border-bottom: 1px solid #00000029 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.connect p{
  cursor: pointer;
}
.connect p:hover{
  color:var(--greens);
}
.recent_post {
  /* background-color: #fff;
  margin-top: 50px;
  padding: 20px; */
  background-color: transparent;
    margin-top: 50px;
    padding: 20px;
    border: 1px solid var(--violetbg);
    border-radius: 6px;
}

.vdieo_hide {
  border: 1px solid #eee;
  padding: 0px 40px 40px 40px;
  text-align: center;
}

.form_loist {
  background-color: #fff;
  margin-top: 50px;
  padding: 20px;
}

.newdetailsec h2 {
  color: var(--black);
  font-size: 40px;
  padding: 30px 30px 10px 30px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.recent_post h2 {
  color: var(--black);
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}

.form_loist h2 {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 10px;
}

.imgdive {
  display: flex;
  padding: 0 5px 40px 5px;
  font-size: 0;
  flex-flow: row wrap;
  justify-content: center;
  gap: 1px;
  max-width: 800px;
  margin: 0 auto;
}

.imgs {
  margin: 0;
  min-width: 201px;
  flex: 0.67;
  transition: .5s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 250px;
  max-height: 250px;
}

.imgs img {
  max-width: 100%;
  min-height: 250px;
  object-fit: cover;
}

.recentblogdet {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 20px;
}

.recent_post a {
  color: var(--black) !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
  transition: all 0.2s ease;
  word-break: break-word;
}
.newsbackimg{
  max-width: 100% !important;
}
.recent_post a:hover {
  color: var(--greens) !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
}

.recent_post img {
  width: 80px;
  height: 70px;
  object-fit: contain;
}

.dayscmment {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 30px;
  cursor: pointer;
}

.dayscmment p {
  color: #818181;
  font-size: 14px;
  margin-right: 10px;
}

.newdetailsec .textconete {
  color: var(--black);
  font-size: 14px;
  margin-right: 10px;
  padding: 10px 30px 30px 30px;
  margin-bottom: 0;
}
/* .newdetailsec .textconete p {
  margin-bottom: 0;
} */
.newdetailsec .textconete span{
  color: var(--black) !important;
}
/* .newdetailsec .textconete span{
  color: #B8B8B8 !important;
} */
.newdetailsec .textconete strong{
  color: #fff !important;
}
.dayscmment i {
  color: #818181;
  font-size: 14px;
}

/* banner */

.heading {
  font-size: 55px;
}

.banner {
  position: relative;
  overflow: hidden;

}

.bannerimg img {
  width: 100%;
  max-width: 500px;
}

/* feature */
.features-list {
  border: 1px solid transparent;
  border-radius: 14px;
}

.features-list:hover {
  border: 1px solid var(--themeclr);
}

/* roadmap */
h6.roadmapbtn:after {
  position: absolute;
  content: "";
  height: 46px;
  width: 1px;
  background: white;
  top: -31px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

h6.roadmapbtn {
  position: relative;
}

.line:after {
  content: "";
  height: 20px;
  width: 20px;
  background: #000000;
  display: block;
  color: var(--themeclr);
  position: absolute;
  border-radius: 50%;
  border: 2px solid white;
  left: 0;
  right: 0;
  margin: auto;
  top: 26%;
  z-index: 1;
}

.car-item:after {
  width: 100%;
  content: "";
  height: 6px;
  background: var(--themeclr);
  position: absolute;
  left: 0;
  right: 0;
  /* top: 26%; */
  top: 29%;
}

.fullinerelative {
  position: relative;
}

.fullline {
  position: absolute;
  height: 6px;
  width: 100%;
  background: #2D2D2D;
  top: 77px;
  border-radius: 10px;
}

.roadmap .owl-item:first-child .car-item:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.logogrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  place-items: center;
}

.logogrid img {
  width: 10vw;
  max-height: 3vw;
}

.notyetstart .line:after {
  background: var(--themeclr);
  border-color: #817E7E;
  border-width: 3px;
}

.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-prev:hover {
  background: transparent;
}

.notyetstart:after {
  background: transparent;
}

.inprogress:after {
  width: 50%;
}

.car-item h6:first-child {
  margin-bottom: 66px;
}

.car-item h6:first-child {
  margin-bottom: 66px;
}

.car-item {
  text-align: center;
  position: relative;
  width: 100%;
}

.car-item p {
  color: #CBCBCB;
  font-size: 15px;
  padding-right: 15px;
  min-height: 88px;
}

.infos p,
.marketcapital p {
  font-size: 18px;
}

.roadmap .owl-nav button span {
  margin-top: 0 !important;
}

.car-item .line+h6 {
  margin-top: 80px;
}

.car-item h6 {
  color: white;
}

h6.roadmapbtn {
  background: var(--themeclr);
  max-width: 114px;
  padding: 7px;
  border-radius: 20px;
  color: #000;
  margin: auto;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 11px;
}

.slider_sec_1 img {
  max-width: 170px;
  margin: 20px auto;
  transition: all 0.5s ease;
  cursor: pointer;
  /* max-height: 110px;
  min-height: 110px; */
}

.slider_sec_1 .owl-carousel .owl-nav .owl-prev span,
.slider_sec_1 .owl-carousel .owl-nav .owl-next span {
  /* display: none; */
  color: #97afd5;
}

.slider_sec_1 .owl-carousel .owl-nav .owl-prev span,
.slider_sec_1 .owl-carousel .owl-nav .owl-next span {
  color: #97afd5;
  border: 1px solid #97afd5;
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  font-size: 35px;
  font-weight: 100;
  line-height: 0.8;
  margin-top: 40px;
}

.slider_sec_1 .owl-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 150px !important;
}

.slider_sec_1 .owl-item div,
.slider_sec_1 .owl-item div a {
  height: 100%;

}

.slider_sec_1 .owl-item .car-item>div {
  padding-top: 40px;
}



/* launchpad */
.bannerbox .innerheading {
  font-size: 45px;
  text-shadow: 0px 8px 2px #5f59592b;
  font-weight: 600;
}

/* .bordbox {
  border: 1px solid #c766fc87;
  background: url("./Assets/images/slide/tablefit.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 100px 40px !important;
} */
.bordbox1 {
  /* border: 1px solid #3939394d; */
  background: url("Assets/images/lockedstakingline.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 16px;
}

.launchbox {
  /* background-color: #0F0F0F;
  border: 1px solid #313131; */
  position: relative !important;
  z-index: 7;
  /* overflow: hidden; */
}

/* .launchpad button.chocobtn {
  position: absolute;
  right: 24px;
  top: -17px;
} */

.progress {
  background-color: var(--darkgreen);
  height: 10px;
  border-radius: 5px;
}

.progress-bar {
  /* background: var(--textclr); */
  background: var(--violetbg);
  border-radius: 5px;
}

.progresshead {
  margin-bottom: 12px;
}

.launchbox h6 {
  /* font-family: 'bold'; */
  font-size: 15px;
  color: var(--black);
}

.logocont img {
  /* border: 2px solid var(--themeclr);
  border-radius: 50%; */
  /* height: 40px;
  width: 100px;
  padding: 5px;
  object-fit: contain;
  margin-top: -6px; */
  width: 100%;
  max-width: 30px;
}

.logocont span {
  text-transform: uppercase;
  /* font-family: normal; */
}

.launchpad .bgtheme .innerheading {
  text-shadow: 0px 8px 2px #5f59592b;
  font-weight: 600;
}

.calcicon {
  width: 12px;
  filter: hue-rotate(45deg);
}


/* modal */
.modal-backdrop.show {
  opacity: .9;
}

.modal-content {
  background: var(--whites);
}

.modal-backdrop {
  /* background-color: #2E2E28; */
  background-color: #000000;
}

.yellowbox {
  /* border: 1px solid var(--themeclr); */
  /* background: #000000; */
  /* border-radius: 15px; */
  /* background: url("./Assets/images/slide/tablefit.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 60px 40px !important; */
}

.bannerbox {
  min-height: 181px;
}

.modal .yellowbox.connect.text-center {
  max-width: 185px;
  margin: auto;
}

.modal .yellowbox img {
  max-width: 50px;
  height: auto;
}

.modal-sm {
  max-width: 400px;
  margin: auto;
}

.btn-link.close:hover {
  color: var(--themeclr);
}

.input-group input {
  background: transparent !important;
  border: none;
  border-radius: 6px;
  color: var(--backcolor);
  min-height: 48px;

}

.input-group.h-75 input {
  height: 75px;
}

span.input-group-text {
  background: #DAE3F8;
  border: none;
  border-radius: 6px;
}

.form-control:focus {
  color: var(--black) !important;
}

.input-group {
  /* border: 1px solid var(--themeclr); */
  border: 1px solid #00000029;
  border-radius: 8px;
}

.input-group input:focus {
  box-shadow: none;
}

.form_loist label {
  color: #5e5e5e;
  font-size: 12px;
}

.labelforme {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.form_loist .labelforme textarea {
  --webkit-appearance: none !important;
  appearance: none !important;
  border: 1px solid #5e5e5e33 !important;
  margin-top: 10px;
  width: 98%;
}

.form_loist .labelforme textarea:focus {
  outline: none;
}

.form_loist .labelforme input[type="text"] {
  --webkit-appearance: none !important;
  appearance: none !important;
  border: 1px solid #5e5e5e33 !important;
  max-width: 95%;
  min-width: 80%;
  height: 40px;
  width: 100%;
}

.labelformecheck {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.submitfomer {
  background-color: #F6DF2D;
  font-size: 14px;
  color: #000;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
  padding: 12px 14px !important;
  height: 50px;
  margin-top: 30px;
  border: 1px solid #F6DF2D;
  transition: all 0.5s ease;
}

.vdieo_hide iframe {
  max-width: 100%;
  width: 550px;
  max-height: 300px;
  height: 300px;
  min-height: 300px;
}

.submitfomer:hover {
  background-color: transparent;
  color: #000;
}

.labelformecheck p {
  color: #5e5e5e;
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 10px;
}

.form_loist .labelforme input:focus {
  outline: none;
}

.inputfoe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

/* footer */


.footer .textclr {
  /* font-family: light; */
  color: #fff;
  font-size: 12px;
}

.footer a svg {
  /* color: #74E842; */
  color: var(--greens);
  font-size: 18px;
  margin-left: 10px;
}

.footer a svg:hover {
  /* color: #ab3ee7; */
  color: var(--navhover);
}



.footer ul li span {
  color: var(--textclr);
  font-size: 25px;
}



@media(min-width:1601px) {

  .car-item p {
    min-height: 78px;
  }

  .line:after {
    top: 23%;
  }

}

@media(max-width:1199px) {
  .smallp {
    max-width: 100%;
  }

  .news .itemsfles {
    min-height: 60vh;
  }

  .news .itemsfles h2 {
    font-size: 40px !important;
  }

  .news .items {
    height: 60vh;
  }

  .news .owl-carousel .owl-nav {
    bottom: 50px;
  }
}

@media(max-width:992px) {
  .news .itemsfles h2 {
    font-size: 40px;
  }

  .news .itemsfles {
    min-height: 50vh;
  }
}

@media(max-width:991px) {


  .news .items {
    height: 90vh;
    background-size: 100%;
  }

  .news .itemsfles {

    width: 100%;
    left: 0;
  }

  .news .itemsfles p {
    width: 80%;
  }

  .news .itemsfles h2 {
    font-size: 36px;
  }

  .navbar-collapse a {
    max-width: max-content;
    max-width: fit-content;
  }

  .bannerbox .innerheading {
    font-size: 30px;
  }

  .header .navbar-nav a {
    margin: 0;
  }

  .heading {
    font-size: 40px;
  }

  .bannerimg img {
    max-width: 390px;
  }
}

@media(max-width:767px) {

  .bordbox {
    /* border: 1px solid #c766fc87; */
    background:url("./Assets//images/slide/tablebg1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 100px 40px !important;
  }

  .infos p,
  .marketcapital p {
    font-size: 16px;
  }

  .news .owl-carousel .owl-nav {
    bottom: 30px;
  }

  .news .itemsfles {
    min-height: 41vh;
  }

  .news .items {
    height: 90vh;
    background-size: 100% 100%;
  }

  .navbar-brand img {
    height: 40px;
  }

}

@media(max-width:575px) {
  .whites .innerheading {
      font-size: 29px;
      line-height: 42px;
  }
  .whitepapercontents .centerhead {
    margin: 40px 0px;
}
  .bordbox {
    padding: 20px 20px 30px 20px !important;
}
  .footer .textclr {
    text-align: center;
  }
}

.logos_sec {
  max-width: 80%;
  margin: 0 auto;
}

@media(max-width:575px) {
  .newdetailsec {
    margin-top: -10px;
  }

  .logogrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media(max-width:425px) {
  .slider_sec_1 img {
    max-width: 99px;
    margin: 0px auto;
  }


  /* .newdetail {
    min-height: 40vh;
    max-height: 40vh;
  } */

  .newdetail img {
    min-height: unset;
  }

  .items .newdetail img {
    min-height: 100vh;
  }

  .news .itemsfles p {
    font-size: 14px;
  }

  .newdetailsec h2 {
    font-size: 28px;
  }

  .connect p {
    font-size: 12px;
  }

  .news .itemsfles h2 {
    font-size: 24px !important;
  }

  .news .owl-carousel .owl-nav button.owl-next,
  .news .owl-carousel .owl-nav button.owl-prev {
    font-size: 22px;
    width: 32px;
  }

  .news .owl-carousel .owl-nav {
    left: 0;
    right: 0;
  }
}

@media(min-width:1800px) {
  .heading {
    font-size: 75px;
  }
}

.news .Editor_pick.firstsec .token_list {
  height: 100%;
}

.newdetailsec h2 {
  font-weight: 900;
  background: var(--darkgreen);
}

.newdetailsec .textconete b {
  font-size: 16px;
}

.launchpad .noticelink {
  /* color: #838383 !important; */
  color: #ffff !important;
  font-size: 18px;

}

.launchpad .menu_list {
  position: relative;

}

.launchpad .notice_link {
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.launchbox .chocobtn, .bordbox .headingbtn, button.ovalbtn {
  background: url("Assets/images/stopped.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-width: fit-content;
}

.bordbox .headingbtn, button.ovalbtn {
  background-image: url("Assets/images/unlockwallet.png");
  color: #fff !important;
}

.launchbox .lauchboxhead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url("Assets/images/launchheader.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding: 16px 25px 14px;
}

.launchbox .bordbox {
  background: url("Assets/images/launchbody.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 50px;
}

.launchbox .bordbox .bordboxinner {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 24px 18px;
}

.launchbox .bordbox .bordboxinner p {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  color: var(--paracontent);
}

.launchbox .bordbox .bordboxinner p + p {
  font-weight: 600;
}

.launchbox .bordbox .bordboxinner h6 {
  margin-bottom: 0;
  font-weight: 600;
  line-height: 22px;
}


.logocont span {
  font-weight: 600;
  display: inline-block;
  font-size: 15px;
}

.logocont {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.bordbox .textclr {
  color: var(--violetbg);
  font-size: 16px;
  font-weight: 500;
}

.modal {
  color: var(--backcolor);
  font-weight: 500;
}

.modal button.chocobtn {
  color: var(--black) !important;
}

.modal .close {
  color: var(--black) !important;
}

.stakingcards .launchbox {
  background: url("Assets/images/lockedstakingbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 40px 30px 50px;
}

.livelabel {
  font-size: 16px;
  color: var(--black);
  font-weight: 500;
}

.stakingcards .row>div:nth-child(3n + 2) {
  padding-left: 0;
  padding-right: 0;
}

.bordbox1 p {
  font-weight: 500;
}

.stakingcardbox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px 20px;
}

.newshead {
  padding-bottom: 60px;
}

.launchbox .cardstar {
  width: 100%;
  max-width: 100px;
  position: absolute;
  top: -58px;
  left: -60px;
}

.launchbox .launchlines, .launchbox .launchlinestwo {
  width: 100%;
  max-width: 300px;
  transform: rotate(90deg);
  position: absolute;
  left: -185px;
  top: 0px;
  bottom: 0;
  margin: auto;
}

.launchbox .launchlinestwo {
  left: auto;
  right: -185px;
  transform: rotate(-90deg);
}

.tabsection {
  overflow: hidden;
}

/* .react-transform-wrapper {
  padding-top: 14% !important;
} */
.react-transform-wrapper {
  padding-top: 9% !important;
}



@media (min-width: 1800px) {
  .launchbox .launchlines, .launchbox .launchlinestwo {
    left: -200px;
  }

  .launchbox .launchlinestwo {
    right: -200px;
    left: auto;
  }
  .react-transform-wrapper {
    padding-top: 7% !important;
  }
  /* .react-transform-wrapper {
    padding-top: 10% !important;
  } */
}

@media (max-width: 1300px) {
  .launchbox .launchlines, .launchbox .launchlinestwo {
    max-width: 275px;
    left: -170px;
  }

  .launchbox .launchlinestwo {
    right: -170px;
    left: auto;
  }
  .react-transform-wrapper {
    padding-top: 10% !important;
}
  /* .react-transform-wrapper {
    padding-top: 15% !important;
} */
}

@media (max-width: 1050px) {
  .launchbox .bordbox .bordboxinner {
    grid-template-columns: repeat(4, 1fr);
  }
  .stakingcardbox {
    grid-template-columns: repeat(2, 1fr);
  }

  .launchbox .launchlines, .launchbox .launchlinestwo {
    max-width: 210px;
    left: -135px;
  }

  .launchbox .launchlinestwo {
    right: -135px;
    left: auto;
  }

  .react-transform-wrapper {
    padding-top: 13% !important;
}
  /* .react-transform-wrapper {
    padding-top: 25% !important;
} */
}

@media (max-width: 991px) {
  .container.container-theme {
    max-width: 100%;
  }

  .recent_post {
    margin-top: 20px;
  }

  .launchbox .launchlines, .launchbox .launchlinestwo {
    display: none;
  }

  .launchbox .cardstar {
    left: -42px;
  }

  .react-transform-wrapper {
    padding-top: 17% !important;
}
}

@media (max-width: 767px) {
  .stakingcardbox {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 576px) {
  .launchbox .bordbox .bordboxinner {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}
.newdetailsec h2, .newdetailsec .textconete {
  padding-left: 24px;
  padding-right: 24px;
}

.vdieo_hide {
  padding: 0px 30px 20px;
}

.react-transform-wrapper {
  padding-top: 37% !important;
}
/* .react-transform-wrapper {
  padding-top: 0% !important;
} */

.react-transform-wrapper .mapsvg {
  top: 940px !important;
  transform: scale(2.8);
}

}

@media (max-width: 576px) and (min-height: 850px) {
  .react-transform-wrapper .mapsvg {
    top: 1040px !important;
}
}
@media (max-width: 576px) and (max-height: 650px) {
  .react-transform-wrapper .mapsvg {
    top: 780px !important;
}
}

.modal .bordbox1 {
  background: none;
  /* border: 1px solid var(--violetlightbg); */
  box-shadow: 0px 0px 7px 2px #00000029;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  max-width: 350px;
  margin: 0px auto 18px;
  padding: 15px 20px;
}

.modal .bordbox1:hover {
  box-shadow: 0px 0px 15px 2px #00000029;
}

.modal .bordbox1 p {
  color: var(--black);
  font-weight: 600;
}

/* .modal .bordbox1 p:hover {
  font-weight: 600;
} */

.connectmodal .close {
  width: fit-content;
  margin: 14px 14px 0px auto;
  border: 1px solid #00000029;
  border-radius: 50px;
  padding: 0;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.modal .modal-content {
  border-radius: 16px;
}


.whitepapercontents .centerhead {
  color: var(--backcolor);
  font-size: 18px;
  font-weight: 500;
  margin: 70px 0px;
  text-align: center;
}

.whitepapercontents .whitepaper_centerimg {
  /* width: 100%;
  max-width: 260px; */
  display: block;
  margin: auto;
}

.whitepapercontents .fnt-18 {
  font-size: 18px !important;
}

.whitepapercontents .rightnum {
  position: relative;
}
.fw-500{
  font-weight: 550;
}
.whitepapercontents .rightnum span {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
}
.whitepapercontents{
  position: relative;
  z-index: 4;
}
.whitepapercontents::before{
    content: "";
    position: absolute;
    background-color: var(--violetlightbg);
    top: 50px;
    left: 0;
    filter: blur(50px);
    width: 60px;
    height: 100px;
    opacity: 0.7;
    z-index: -1;
}
.whitepapercontents::after {
  content: "";
  position: absolute;
  width: 80px;
  height: 100px;
  display: block;
  filter: blur(50px);
  background-color: var(--violetbg);
  filter: drop-shadow(0px 3px 6px black) blur(50px);
  bottom: 50px;
  right: 0px;
  opacity: 0.7;
  z-index: -1;
}

/* .Editor_pick.firstsec .col_token{
  height: 100%;

} */