body {
  font-family: "Montserrat", sans-serif !important;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
}

section {
    padding: 80px 0px;
}

.content {
    color: var(--paracontent);
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    margin: 0;
}

.sectionhead {
    color: var(--black);
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.bannersection {
    background: linear-gradient(to left, #DAE3F8, #F7FBE1);
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;    
    /* padding: 100px 0px 250px; */
    overflow: hidden;
    padding-bottom: 20px;
}

.bannersection::before, .bannersection::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    display: block;
    background-color: #7547F1;
    filter: drop-shadow(0 3px 6px #000) blur(50px);
    top: 0;
    right: 0;
}

.bannersection::after {
    background-color: #D2E828;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 140px;
}

.bannersection .container {
    max-width: 85%;
    position: relative;
    z-index: 9;
}

.bannersection_centerimg {
    width: 100%;
    max-width: 650px;
    display: block;
    margin: auto;
    position: relative;
    z-index: 9;
}

.bannersection_linesimg {
    width: 100%;
    position: absolute;
    right: 6%;
    bottom: -10%;
    top: 0;
    max-width: 800px;
    opacity: 0.3;
}

.bannersection_head {
    font-size: 40px;
    color: var(--black);
    font-weight: 700;
    margin-bottom: 0;
    line-height: 52px;
}

.bannersection_head:hover {
    color: var(--violetbg);
}

.bannersection_video {
    width: auto;
    height: auto;
    position: absolute;
    inset: 0;
    mix-blend-mode: screen;
    opacity: 0.8;
}

.sitebtn {
    color: var(--black);
    font-size: 15px;
    background-color: var(--darkgreen);
    border-radius: 10px;
    padding: 10px 24px;
    font-weight: 500;
    display: inline-block;
}

.bannersection .sitebtn {
    width: 100%;
    max-width: 160px;
}

.sitebtn:hover {
    background-color: var(--violetbg);
    color: #fff;
}

.bannersection_contents {
    position: relative;
}

/* .bannersection_contents {
    position: absolute;
} */

.partners {
    padding: 18px 0px;
    /* padding: 30px 0px; */
    background-color: var(--black);
}

.rotateboxparent {
    width: 280px;
    height: 280px;
    border: 1px solid var(--darkgreen);
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    transition: transform 1.5s;
    margin: auto;
    z-index: 9;
    display: grid;
    place-content: center;
}

.rotateboxlogo {
    width: 100%;
    max-width: 150px;
    position: absolute;
    inset: 0;
    margin: auto;
}

.features_rotatebox {
    position: relative;
}

.features_rotate {
    content: '';
    position: absolute;
    width: 280px;
    height: 280px;
    border: 3px solid var(--darkgreen);
    display: block;
    margin: auto;
    inset: 0;
    border-radius: 50%;
    animation: bordernotify 0.8s infinite linear;
    transition: animation 0.2s;
}

@keyframes bordernotify {
    0% {
        transform: scale(1);
        opacity: 0.4;
    }
    
    50% {
        transform: scale(1.15);
        opacity: 0.2;
    }

    100% {
        transform: scale(1.15);
        opacity: 0;
    }
}

.rotateboxparent.box1 {
    transform: rotate(0);
}

.rotateboxparent.box2 {
    transform: rotate(90deg);
}

.rotateboxparent.box3 {
    transform: rotate(180deg);
}

.rotateboxparent.box4 {
    transform: rotate(270deg);
}

.rotatebox {
    width: 130px;
    height: 130px;
    background-color: #f0f3d6;
    margin-bottom: 10px;
    border-radius: 50%;
    content: "";
    position: absolute;
    cursor: pointer;
    color: #000;
    display: flex;
    padding: 16px;
}

.rotatebox>div {
    transition: all 1.5s;
}

.rotatebox.one {
    inset: 0px;
    top: auto;
    bottom: -75px;
    margin: auto;
    justify-content: center;
    align-items: start;
    width: 200px;
}

.rotatebox.four {
    inset: 0px;
    right: auto;
    left: -75px;
    margin: auto;
    justify-content: end;
    align-items: center;
    height: 200px;
}

.rotatebox.two.active>div {
    transform: rotate(-90deg);
}

.rotatebox.three {
    inset: 0px;
    top: -75px;
    bottom: auto;
    margin: auto;
    justify-content: center;
    align-items: end;
    width: 200px;
}

.active.rotatebox.three>div {
    transform: rotate(-180deg);
}

.rotatebox.two {
    inset: 0px;
    left: auto;
    right: -75px;
    margin: auto;
    justify-content: start;
    align-items: center;
    height: 200px;
}

.active.rotatebox.four>div {
    transform: rotate(-270deg);
}

.rotatebox.active {
    background-color: var(--darkgreen);
}

.rotatebox .rotateicon {
    width: 100%;
    max-width: 24px;
}

.features {
    background-color: var(--darkgreen);
    overflow: hidden;
}

.features_bg {    
    height: 100%;
    max-height: 1100px;
    position: relative;
    overflow: hidden;
}

.features_centerbox {
    margin-top: 60px;
}

.features_vimg {
    width: 100%;
    max-width: 340px;
    transform: rotate(90deg) translateX(60px);
    display: block;
    margin: auto;
}

/* .featurebox {
    display: flex;
    position: relative;
    justify-content: center;
} */

.featurebox .featbox {
    width: 100%;
    max-width: 600px;
    margin: auto;
    /* position: absolute; */
    display: block;
    /* left: 0; */
    /* right: -100%; */
    /* opacity: 0; */
    transition: display 1.5s;
    /* z-index: -1; */
    /* display: none; */

}

/* .featurebox .featbox.active {
    display: block;
    opacity: 1;
} */


.featurebox .carousel-inner {
    overflow: visible;
}

.featurebox .feathead {
    font-size: 16px;
    background: url("Assets/images/feathead.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    color: var(--black);
    font-weight: 600;
    padding: 16px;
    margin-bottom: 0;
    word-break: break-all;
}

.featurebox .featbody {
    background: url("Assets/images/featbody.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 5px 16px 35px;
}

.featurebox .content {
    font-size: 14px;
    word-break: break-all;
}

.features .content a {
    color: var(--darkgreen) !important;
}

a.underline:hover {
    text-decoration: underline !important;
}


.rotateboxparent:not(.box1) .rotatebox:not(.active) > div {
    transform: rotate(-90deg);
}

.rotateboxparent.box3 .rotatebox:not(.active) > div {
    transform: rotate(-180deg);
}

.rotateboxparent.box4 .rotatebox:not(.active) > div {
    transform: rotate(-270deg);
}

.featurebox .carousel-item {
    transition-duration: 1.5s;
    transition-timing-function: ease;
}

.info {
    background-color: var(--violetbg);
    /* position: relative; */
}

.info, .features {
    padding: 40px 0px;
}

.info_bg, .features_bg {
    /* background: url("Assets/images/infobg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%; */
    /* background-size: contain;
    min-height: 820px; */
    /* padding: 90px;s */
    position: relative;
}


.info .info_contentbox, .features .features_contentbox {
    content: "";
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 75px;
}

.info .info_contentbox {
    padding: 0;
    max-width: 85%;
}

.info_bg {    
    height: 100%;
    max-height: 850px;
    position: relative;
    overflow: hidden;
}


.features .features_contentbox {
    padding: 75px 0px 0px;
}

.info_bgimg {
    width: 100%;
}

.info_greenline, .info_greenlinetwo {
    width: 100%;
    max-width: 18px;
    position: absolute;
    right: 114px;
    top: 44%;
}

.info_greenlinetwo {
    right: auto;
    top: auto;
    left: 58px;
    bottom: 31%;
}

.info_greenlinethree, .info_greenlinefour {
    max-width: 50px;
    right: auto;
    top: 33%;
    left: 37%;
}

.info_greenlinefour {
    right: 0px;
    left: auto;
    top: 46%;
    right: 38px;
}

.info_centercircle {
    width: 100%;
    max-width: 480px;
    margin-left: auto;
    position: relative;
    left: 70px;
}

.info_center {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    margin-top: 70px;
}

.info_centerbg {
    background: url("Assets/images/infocenterbg.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 30px 30px 35px;
    padding-right: 40px;
    position: relative;
    width: 100%;
    max-width: 450px;
    height: 100%;
    transition: all 0.3s;
}
.info_centerbg:hover {
    transform: scale(1.05);
}
.info_centerbg:not(:last-child) {
    margin-bottom: 30px;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.info_centerbg0, .info_centerbg1, .info_centerbg2 {
    animation: pulse 2s linear;
}

.info_centerbg0 {
    animation-delay: 1s;
}

.info_centerbg1 {
    animation-delay: 2s;
}
.info_centerbg2 {
    animation-delay: 3s;
}

.info_centerbg_span {
    font-size: 14px;
}

.info_content {
    font-weight: 600;
    margin-bottom: 8px;
}

.info_centerbg_span {
    color: var(--paracontent);
    display: block;
    font-weight: 600;
}

.secthead {
    display: inline-block;
    position: relative;
    margin-bottom: 22px;
}

.sectionheadline {
    width: 100%;
    position: absolute;
    bottom: -18px;
}

.greenbg {
    color: var(--darkgreen);
}

.trustby {
    position: relative;
    overflow: hidden;
    background-color: #F0F2F7;
}

.trustby::before, .trustby::after {
    content: "";
    position: absolute;
    width: 80px;
    height: 100px;
    display: block;
    background-color: var(--violetbg);
    filter: drop-shadow(0px 3px 6px black) blur(50px);
    top: 0;
    right: 0px;
    opacity: 0.7;
}

.trustby::after {
    background-color: var(--violetlightbg);
    top: auto;
    right: auto;
    left: 0;
    bottom: 60px;
    filter: blur(50px);
    width: 60px;
}



.trustby_img {
    width: 100%;
    max-width: 850px;
    margin: 50px auto auto;
}

.clients {
    padding: 40px 0px;
    background-color: #fff;
}

.clients_centerbox {
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
}

.clients_img {
    width: 100%;
    max-width: 850px;
    display: block;
    margin: auto;
}



.clients_bg {
    background: linear-gradient(to right, #F0F2F7, #E9E3F6);
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.clients_bgimg {
    width: 100%;
    position: absolute;
    inset: 0;
    transform: scale(3);
    opacity: 0.05;
}

.roadmap {
    padding-top: 0px;
    background-color: #fff;
}

.roadmap_bg {
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    height: 100%;
    max-height: 650px;
}

.roadmap_centerbox {
    content: '';
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    /* content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99; */
}

.roadmap_accordionbox {
    width: 100%;
    max-width: 320px;
    height: 320px;
    transform: rotate(-90deg);
    margin: 70px auto auto;
    display: flex;
    align-items: center;
}

.roadmap_accordionbox .accordion {
    flex-grow: 1;
}

/* .roadmap_accordionbox .accordion {
    transform: rotate(-90deg);
} */

.roadmap_accordionbox .accordion .accordion-item {
    background-color: transparent;
    border: 0px;
    border-radius: 0px;
}

.roadmap_accordionbox .accordion .accordion-item:not(:last-child) {
    margin-bottom: 25px;
}

.roadmap_accordionbox .accordion .accordion-button {
    background-color: #7146E5;
    color: #fff;
    font-weight: 600;
    padding: 0;
    justify-content: space-between;
    border-radius: 20px !important;
    border: 0px;
    box-shadow: none;
}

.roadmap_accordionbox .accordion .accordion-button:focus {
    box-shadow: none;
}

.roadmap_accordionbox .accordion .accordion-button:hover {
    z-index: 0;
}

.roadmap_accordionbox .accordion-button span {
    display: block;
    /* flex-grow: 1; */
}

.roadmap_accordionbox .accordion_buttonhead, .roadmap_accordionbox .accordion_buttonnum {
    padding: 35px;
}

.roadmap_accordionbox .accordion_divbuttonnum {
    flex-grow: 1;
}
.roadmap_accordionbox .accordion_buttonnum {
    text-align: center;
    writing-mode: vertical-rl;
    height: 100px;
    background-color: var(--darkgreen);
    color: var(--black);
    font-weight: 600;
    font-size: 22px;
    margin-left: auto;    
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    padding: 16px;
}

.roadmap_accordionbox .accordion_buttonhead {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    text-align: end;
    word-break: break-all;
}

.roadmap_accordionbox .accordion_divbuttonhead {
    flex-grow: 1;
}




.roadmap_accordionbox .accordion .accordion-button::after {
    background-image: none;
    width: auto;
    height: auto;
}

.roadmap_accordionbox .accordion .accordion-button:not(.collapsed)::after {
    position: absolute;
    bottom: -14px;
    right: 36px;
    /* width: 20px; */
    /* height: 20px; */
    display: block;
    /* background: black; */
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 14px solid var(--darkgreen);
    transform: none;
}

.roadmap_accordionbox .accordion-body {
    background-color: var(--darkgreen);
    border-radius: 14px;
    padding: 24px 24px;
    margin-top: 20px;
    writing-mode: vertical-rl;
    height: 300px;
    width: 100%;
}

/* .roadmap_accordionbox .accordion-body .content {
    transform: rotate(90deg);
} */

.roadmap_accordionbox .accordion-body .content, .roadmap_accordionbox .accordion-body .content p {
    margin-bottom: 0;
}

.clients .clientsimgsbox {
    position: relative;
}

.clients_centerbox .rotateboxlogo {
    z-index: 9;
    inset: -32% 0 0 2px;
    /* inset: -18px 0 0 3px; */
}


.roadmap_accordionbox .accordion {
    overflow: auto;
    height: 50rem;
    direction: rtl;
    padding-left: 20px;
}

.roadmap_accordionbox .accordion::-webkit-scrollbar {
    width: 4px;
    height: 2px;
    background-color: transparent;
}
.roadmap_accordionbox .accordion::-webkit-scrollbar-track {
    background-color: transparent;
}

.roadmap_accordionbox .accordion::-webkit-scrollbar-thumb {
    background-color: var(--darkgreen);
}

.roadmap_accordionbox .accordion .accordion-button, .roadmap_accordionbox .accordion-body {
    direction: ltr;
}

.clients .clientscontent {
    position: relative;
    z-index: 99;
}


.partners .partnerimgbig, .partners .partnerimgmed {
    width: 100%;
    max-width: 270px;
}

.partners .partnerimgmed {
    max-width: 180px;
}


@media (min-width: 1800px) {
    .bannersection_head {
        font-size: 60px;
    }

    .sectionhead {
        font-size: 36px;
    }

    .info_centercircle {
        max-width: 500px;
    }
    .trustby_img {
        max-width: 1000px;
    }
    .clients_img {
        max-width: 1000px;
    }

    .bannersection_linesimg {
        right: 10%;
        max-width: 1000px;
    }

    /* .clients_bg .clients_centerbox .rotateboxlogo {
        inset: -78px 0 0 3px;
    } */
    
    .bannersection_centerimg {
        max-width: 700px;
    }
}

@media (min-width: 1900px) {
    .bannersection_linesimg {
        right: 8%;
        bottom: -8%;
        max-width: 1100px;
    }
}

@media (min-width: 2500px) {
    .features .features_contentbox, .info .info_contentbox {
        padding-top: 120px;
    }

    .features_bg {
        max-height: 1150px;
    }

    .info_bg {
        max-height: 900px;
    }
}

@media (1400px <= width <= 1499px) {
    .bannersection_linesimg {
        right: 8.5%;
        bottom: -9%;
    }
}
@media (1500px <= width <= 1599px) {
    .bannersection_linesimg {
        right: 12%;
        bottom: -10%;
    }  
    
}
@media (1600px <= width <= 1699px) {
    .bannersection_linesimg {
        right: 13%;
        bottom: -9%;
    }
}

/* @media (min-width: 1500px) {
    .clients_centerbox  .rotateboxlogo {
        inset: -47px 0 0 3px;
    }
} */


@media (1200px <= width <= 1399px) {
    .features .features_contentbox {
        transform: scale(0.93);
    }
}

@media (1200px <= width <= 1300px) {
    .features_bgimg svg, .info_bgimg svg {
        width: 115% !important;
    }
}
@media (1020px <= width <= 1050px) {
    .info_bgimg svg {
        width: 130% !important;
    }

    .features_bgimg svg {
        width: 135% !important;
    }

    /* .clients_centerbox .rotateboxlogo {
        inset: 7px 0 0 3px;
    } */

    .bannersection .bannersection_linesimg {
        right: -8%;
        bottom: -7%;
    }
}

/* @media (min-width: 1024px) and (max-height: 768px) {
    .bannersection .bannersection_linesimg {
        right: -8%;
        bottom: -7%;
    }
} */

@media (max-width: 1400px) {
    .bannersection_centerimg {
        max-width: 500px;
    }
}
@media (max-width: 1300px) {
    .bannersection .container, .info .info_contentbox {
        max-width: 95%;
    }

    .info .info_contentbox {
        padding: 0 10px;
    }

    .bannersection_linesimg {
        right: 2%;
        bottom: -9%;
    }

    
}

@media (min-width: 1200px) and (max-height: 600px) {
    .bannersection_linesimg {
        bottom: -12.5%;
    }
}

@media (max-width: 991px) {
    .headermenus .navbar .nav-link:not(:last-child) {
        margin-right: 18px;
    }

    .bannersection {
        height: auto;
        padding-top: 20vh;
        padding-bottom: 30px;
    }

    .roadmap_bgimg {
        display: none;
    }

    .roadmap_bg {
        max-height: max-content;
        background-color: #F7FBE1;
        padding: 30px;
    }

    .roadmap_centerbox {
        position: static;
        left: auto;
        top: auto;
        transform: none;
    }

    .roadmap_accordionbox {
        max-width: none;
        height: auto;
        transform: none;
        margin: 50px auto auto;
        display: block;
    }

    .roadmap_accordionbox .accordion_buttonnum {
        writing-mode: unset;
        height: 100%;
    }

    .roadmap_accordionbox .accordion_divbuttonnum span {
        display: flex;
        align-items: center;
    }

    .roadmap_accordionbox .accordion_divbuttonnum {
        flex-grow: 0;
    }

    .roadmap_accordionbox .accordion_buttonhead, .roadmap_accordionbox .accordion_buttonnum {
        padding: 20px;
    }

    .roadmap_accordionbox .accordion_buttonhead {
        text-align: center;
    }

    .roadmap_accordionbox .accordion-body {
        writing-mode: unset;
        height: auto;
    }

    .bannersection_linesimg {
        display: none;
        position: static;
        inset: auto;
    }

    .info_bg, .features_bg {
        max-height: max-content;
        overflow: visible;
    }

    .info .info_contentbox, .features .features_contentbox {
        position: static;
        inset: auto;
        padding: 40px;
        display: block;
        background: url("Assets/images/infomobilebg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 30px;
    }

    .info_bgimg, .features_bgimg {
        display: none;
    }

    .info_centercircle {
        margin-left: 0;
        left: auto;
        max-width: 270px;
        margin: auto;
    }

    .info_centerbg {
        max-width: 390px;
        margin: auto;
    }

    .info_center {
        margin-top: 40px;
    }

    .info_centerbgtop {
        margin-top: -100px;
    }

    .headermenus .navbar .container {
        border-radius: 0px;
        padding: 12px;
        width: 100%;
        max-width: 100%;
    }

    .headermenus .navbar .container::before, .headermenus .navbar .container::after {
        width: 0;
        height: 0;
        top: auto;
        left: auto;
        box-shadow: none;
        transform: none;
    }

    .navbar-toggler-icon {
        display: none;
    }

    .navbar-toggler {
        border: 0px;
        padding: 0;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .navbar-toggler span {
        display: block;
        width: 30px;
        height: 2px;
        background-color: var(--darkgreen);
        transition: all 0.3s;
    }

    .navbar-toggler span:not(:last-child) {
        margin-bottom: 7px;
    }

    .navbar-toggler:not(.collapsed) span:first-child {
        transform: rotate(45deg) translate(3px, 13px);
    }
    .navbar-toggler:not(.collapsed) span:nth-child(2) {
        opacity: 0;
    }
    .navbar-toggler:not(.collapsed) span:last-child {
        transform: rotate(-45deg) translate(0px, -10px);
    }

    .navbar-collapse {
        border-top: 1px solid var(--grey);
        padding: 15px 0px 7px;
        margin-top: 15px;
    }

    .headermenus .navbar .nav-link {
        margin-bottom: 12px;
    }

    .headermenus .headbtn {
        margin-left: 12px;
    }

    .clients_centerbox .rotateboxlogo {
        max-width: 115px;
        /* inset: -30px 0 0 3px; */
    }

    .roadmap_accordionbox .accordion .accordion-button {
        align-items: stretch;
    }

    .clients_centerbox {
        padding: 30px;
    }

    .bannersection .container, .info .info_contentbox {
        max-width: 100%;
    }

    .roadmap_accordionbox .accordion {
        height: 30rem;
        direction: ltr;
        padding-left: 0;
        padding-right: 14px;
    }
}

@media (max-width: 767px) {
    

    .info .info_contentbox, .features .features_contentbox {
        padding: 30px 30px;
        border-radius: 20px;
    }
}

@media (max-width: 576px) {
    

    

    .info_centerbg {
        max-width: 100%;
    }

    .info_centerbg {
        background-size: 100% 100%;
    }

    .featurebox .feathead {
        background-size: 100% 100%;
    }

    .featurebox .featbody {
        padding-bottom: 60px;
    }

    .clients_centerbox .rotateboxlogo {
        max-width: 45px;
        /* inset: 2px 0 -223px 1px; */
    }

    .launchbox .lauchboxhead {
        align-items: end;
    }

    
}

/* @media (max-width: 450px) {
    .clients_centerbox .rotateboxlogo {
        inset: 2px 0 -162px 1px;
    }
} */

/* @media (max-width: 400px) {
    .clients_centerbox .rotateboxlogo {
        inset: 2px 0 -172px 1px;
    }
} */

@media (max-width: 320px) {
    .rotateboxparent {
        width: 250px;
        height: 250px;
    }

    .features_rotate {
        width: 250px;
        height: 250px;
    }

    /* .clients_centerbox .rotateboxlogo {
        inset: 44px 0 -203px 1px;
    } */
}
