
:root {
    --black: #161515;
    --paracontent: #333333;
    --darkgreen: #D2E828;
    --darkbg: #1A1A1A;
    --violetbg: #7547F1;
    --violetlightbg: #7F52FA;
    --grey: #333333;
    --gradientleft: #F7FBE1;
    --gradientright: #DAE3F8;
}

script + iframe {
    display: none;
}

/* header css */

.headermenus {
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
}

.headermenus .navbar {
    padding: 0;
}

.headermenus .navbar .container {
    background-color: var(--black);
    padding: 12px 40px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    position: relative;
}

.headermenus .navbar .container::before, .headermenus .navbar .container::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 30px 20px;
    top: -2px;
    left: -41px;
    box-shadow: 0px 30px 0px 0px #161515;
    transform: rotate(-115deg);
}

.headermenus .navbar .container::after {
    left: auto;
    top: 0;
    right: -40px;
    border-radius: 30px;
    transform: rotate(130deg);
}

.headermenus .navbar-brand {
    padding: 0;
}

.logo {
    width: 100%;
    max-width: 150px;
}

.headermenus .navbar .nav-link {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    transition: none;
    padding: 4px 12px;
    border-radius: 7px;
}

.headermenus .navbar .nav-link:not(:last-child) {
    margin-right: 5px;
}

.headermenus .navbar .nav-link:is(:hover, :focus) {
    color: var(--black);
    background-color: var(--darkgreen);

}

.headermenus .headbtn {
    border-radius: 5px;
    padding: 6px 15px;
    color: #fff;
    background-color: transparent;
    border-color: var(--darkgreen);
}

.headermenus .headbtn:hover {
    background-color: var(--darkgreen);
    color: var(--black);
}

/* .innerpages .headermenus .navbar .container::before, .innerpages .headermenus .navbar .container::after {
    position: static;
    inset: auto;
    width: 0;
    height: 0;
    transform: none;
    box-shadow: none;
  }
  
  .innerpages .headermenus .navbar .container {
    background-color: transparent;
    border-radius: 0px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  
  .innerpages .headermenus {
    border-bottom: 1px solid var(--grey);
  } */


/* header css */






.allsection section {
    padding: 60px 0px;
}

.allsection .banner {
    padding-top: 22vh;
    padding-bottom: 12vh;
}

.titles span {
    color: #fff;
    font-size: 16px;
}

.allsection .banner {
    background: linear-gradient(to right, var(--gradientleft), var(--gradientright));
}

.bannercenter {
    background: url("Assets/images/innerbanner.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 250px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.lockedstake .bannercenter {
    background-image: url("Assets/images/innerlockestakingbanner.png");
}

.lines {
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 90vw;
}

.line1 {
    width: 1px;
    top: 10%;
    left: 50%;
    background: #2d2d2d;
    overflow: hidden;
}

.line1, .lines {
    position: absolute;
    height: 100%;
    z-index: -1;
}

.line1 {
    background: transparent !important;
}

.line1:after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    z-index: -1;
    background: -webkit-linear-gradient(top, hsla(0, 0%, 100%, 0), var(--violetbg) 75%, var(--violetbg));
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, var(--violetbg) 75%, var(--violetbg));
    -webkit-animation: drop 12s 0s infinite;
    animation: drop 12s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(.4,.26,0,.97);
    animation-timing-function: cubic-bezier(.4,.26,0,.97);
}

@keyframes drop {
    0% {
        top: -50%;
    }

    100% {
        top: 100%;
    }
}

.line1:first-child:after {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.line1:nth-child(3):after {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

.line1:first-child {
    margin-left: -38%;
}

.line1:nth-child(3) {
    margin-left: 40%;
}

.innerpages {
    background-color: #fff;
}

.innerpages section {
    padding-top: 180px;
}

.innerhead {
    color: var(--black);
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 40px;
    text-align: center;
    text-transform: capitalize;
}

.paracontents {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.paracontents .content {
    font-size: 15px;
    line-height: 26px;
    color: var(--black);
}


.philosophy .centerimg {
    margin: 25px 0;
    text-align: center;
}

.philosophy .centerimg img {
    width: 100%;
    /* max-width: 500px; */
    height: 100%;
    max-height: 400px;
    object-fit: scale-down;
}
/* .philosophy .centerimg img.centerimglarge {
    max-width: 800px;
} */






/* footer css */

.footer {
    background: linear-gradient(to left, #DAE3F8, #F7FBE1);
    padding: 40px 0px;
}

.footer .container {
    width: 100%;
    max-width: 90%;
}

.footer .footlogo {
    width: 100%;
    max-width: 150px;
}

.socialicons {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 28px;
}

.socialicons li img {
    width: 100%;
    max-width: 28px;
}
  
.socialicons li a {
    text-decoration: none;
}

.foothead {
    color: var(--black);
    font-size: 22px;
    font-weight: 500;
    margin: 0;
}

.footlinks {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.footlinks li:not(:last-child) {
    padding-bottom: 16px;
}

.footlinks li a {
    color: var(--black);
    font-size: 16px;
    font-weight: 500;
}

.footlinks li a:hover {
    font-weight: 600;
}





/* footer css */




/* New css */


/* @media (min-width: 1400px) {
    .allsection .banner {
        padding-top: 20vh;
    }
} */

@media (min-width: 1800px) {

    .launchbox .lauchboxhead {
        padding: 24px 40px 24px;
    }

    .allsection .banner {
        padding-top: 20vh;
    }
}

@media (max-width: 991px) {
    .allsection .banner {
        padding-top: 16vh;
        padding-bottom: 8vh;
      }

      .innerpages section {
        padding-top: 140px;
    }
}


